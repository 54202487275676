import { createContext, FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ISession, ISessionProviderProps, IUpdateSessionOptions } from "./types";
import { IClientSession } from "@portal/pages/api/auth/session/types";
import { Mutex } from "async-mutex";

const REFETCH_INTERVAL_IN_MINUTES = 3;

export const SessionContext = createContext<ISession | null>(null);

export const SessionProvider: FC<ISessionProviderProps> = ({ children }) => {
  const [session, setSession] = useState<IClientSession | null>(null);
  const mutex = useRef(new Mutex());

  const fetchSession = async (url: string) => {
    const serverSessionResponse = await fetch(url);
    const serverSession = (await serverSessionResponse.json()) as IClientSession;
    const clientSessionObject = serverSession?.status ? serverSession : ({ status: "unauthenticated" } as IClientSession);
    return clientSessionObject;
  };

  useEffect(() => {
    const refetchSession = async () => {
      if (mutex.current.isLocked()) return;
      mutex.current.runExclusive(async () => {
        const clientSessionObject = await fetchSession(`/api/auth/session`);
        setSession(clientSessionObject);
      });
    };

    if (session === null) {
      refetchSession();
    }

    const interval = setInterval(() => {
      refetchSession();
    }, 1000 * 60 * REFETCH_INTERVAL_IN_MINUTES);
    return () => clearInterval(interval);
  }, [session]);

  const sessionObject = useMemo(() => {
    const clear = async () => {
      setSession({ status: "unauthenticated" } as IClientSession);
    };

    const update = async (options?: IUpdateSessionOptions) => {
      const url = options?.invalidateInfrontToken
        ? `/api/auth/session?invalidateInfrontToken=${options.invalidateInfrontToken}`
        : "/api/auth/session";

      if (mutex.current.isLocked()) return;
      mutex.current.runExclusive(async () => {
        const clientSessionObject = await fetchSession(url);
        setSession(clientSessionObject);
      });
    };

    return {
      session,
      update,
      clear,
    };
  }, [session]);

  return <SessionContext.Provider value={sessionObject}>{children}</SessionContext.Provider>;
};

export const useSession = (): ISession => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
