export const broadcastSignOut = () => {
  try {
    const channel = new BroadcastChannel("sign-out-channel");
    channel.postMessage("sign out");
  } catch (e) {
    console.error("broadcast failed, sign out", e);
  }
};

export const addSignOutListner = (handler: (event: MessageEvent) => void) => {
  const channel = new BroadcastChannel("sign-out-channel");
  channel.addEventListener("message", event => {
    handler(event);
  });
};

export const removeSignOutListner = (handler: (event: MessageEvent) => void) => {
  const channel = new BroadcastChannel("sign-out-channel");
  channel.removeEventListener("message", handler);
};
